<template>
    <div style="padding:5px; max-width: 1200px; margin: auto">
    <span style="max-width: 1200px;word-break: break-word; white-space: pre-wrap;">
    
    <h1>Terms and Conditions</h1>
    <h2>CRYPTOKEF.COM</h2>
    <p>1.1Cryptokef.com is owned and operated by KEF. (hereinafter "KEF", "We" or "Us"), a company with head office at CG8H+C62, Yuate Tremwatre, New Caledonia. KEF is licensed and regulated by the Government of New Caledonia. Some credit card payment processing are handled by its wholly owned subsidiary, MoneyTech.
</p>
<br>
<h2>IMPORTANT NOTICE</h2>
<p>2.1 BY REGISTERING ON CRYPTOKEF.COM (THE “WEBSITE”), YOU ENTER INTO A CONTRACT WITH KEF, AND AGREE TO BE BOUND BY (I) THESE TERMS AND CONDITIONS; (II) OUR PRIVACY POLICY; (III) OUR COOKIES POLICY AND (IV) THE RULES APPLICABLE TO OUR BETTING OR GAMING PRODUCTS AS FURTHER REFERENCED IN THESE TERMS AND CONDITIONS (“TERMS AND CONDITIONS” OR “AGREEMENT”), AND THE BETTING AND/OR GAMING SPECIFIC RULES, AND ARE DEEMED TO HAVE ACCEPTED AND UNDERSTOOD ALL THE TERMS.
2.2 PLEASE READ THIS AGREEMENT CAREFULLY TO MAKE SURE YOU FULLY UNDERSTAND ITS CONTENT. IF YOU HAVE ANY DOUBTS AS TO YOUR RIGHTS AND OBLIGATIONS RESULTING FROM THE ACCEPTANCE OF THIS AGREEMENT, PLEASE CONSULT A LEGAL ADVISOR IN YOUR JURISDICTION BEFORE FURTHER USING THE WEBSITE(S) AND ACCESSING ITS CONTENT. IF YOU DO NOT ACCEPT THE TERMS, DO NOT USE, VISIT OR ACCESS ANY PART (INCLUDING, BUT NOT LIMITED TO, SUB-DOMAINS, SOURCE CODE AND/OR WEBSITE APIS, WHETHER VISIBLE OR NOT) OF THE WEBSITE.
</p>
<br>
<h2>GENERAL</h2>
<p>3.1 When registering on CRYPTOKEF.COM  You (“You”, “Your”, Yourself” or the “Player” interchangeably) enter into an agreement with KEF.
3.2 This Agreement should be read by You in its entirety prior to your use of KEF's service or products. Please note that the Agreement constitutes a legally binding agreement between you and KEF.
3.3 These Terms and Conditions come into force as soon as you complete the registration process, which includes checking the box accepting these Terms and Conditions and successfully creating an account. By using any part of the Website following account creation, you agree to these Terms and Conditions applying to the use of the Website.
3.4 We are entitled to make amendments to these Terms and Conditions at any time and without advanced notice. If we make such amendments, we may take appropriate steps to bring such changes to your attention (such as by email or placing a notice on a prominent position on the Website, together with the amended terms and conditions) but it shall be your sole responsibility to check for any amendments, updates and/or modifications. Your continued use of the website services after any such amendment to the Terms and Conditions will be deemed as your acceptance and agreement to be bound by such amendments, updates and/or modifications.
3.5 The terms of this Terms and Conditions shall prevail in the event of any conflict between the terms of this Terms and Conditions and of any of the game rules or other documents referred to in this Terms and Conditions.
3.6 These Terms and Conditions may be published in several languages for informational purposes and ease of access by players. The English version is the only legal basis of the relationship between you and us and in the case of any discrepancy with respect to a translation of any kind, the English version of these Terms and Conditions shall prevail.
</p>
<br>
<h2>KEF ACCOUNT</h2>


<p>Registration
4.1 In order for you to be able to place bets on cryptokef.com, you must first personally register an account with us ("KEF Account").
4.2 For a person to be registered as a player with KEF and use the Website, that person must submit an application for registration and opening of a KEF account. The application for the opening of the KEF Account must be submitted personally, and will require You to provide a set of personal information, namely e-mail, name.
4.3 Where the information stipulated in 4.2. is not provided and/or is not deemed to be complete, accurate or up-to-date at any point in time, KEF reserves the right to suspend the KEF Account registration and treat any subsequent potentially accepted deposits to the Player’s KEF Account as invalid (and any winnings arising from such deposit as void). Where a KEF Account is suspended, You should contact customer support at supercryptomegagames@gmail.com.
4.4 All applicants must be 18 or such other legal age of majority as determined by any laws which are applicable to you, whichever age is greater or older. KEF reserves the right to ask for proof of age from any Player and suspend their KEF Account until satisfactory documentation is provided. KEF takes its responsibilities in respect of under age and responsible gambling very seriously.
4.5 KEF will not accept registration from individuals:
a) Under 18 years old or under the legal age of majority or gambling in their jurisdiction;
b) Residing in jurisdictions from where it is illegal or gambling is not permitted. KEF is not able to verify the legality of the Service in each jurisdiction and it is the User's responsibility to ensure that their use of the Service is lawful;
c) Provide misleading information or try to pass by third parties.
4.6 KEF reserves the right to refuse any application for a KEF Account, at its sole discretion.
Know Your Customer
4.7 You represent and warrant that any information provided by You on Your application form is true, updated and correct.
4.8 KEF reserves the right, at any time, to ask for any KYC documentation it deems necessary to determine the identity and location of a Player. KEF reserves the right to restrict the Service, payment or withdrawal until identity is sufficiently determined, or for any other reason in KEF’s sole discretion. KEF also reserves the right to disclose a Player’s information as appropriate to comply with legal process or as otherwise permitted by the privacy policy of KEF (owner and operator of KEF), and by using the Service, you acknowledge and consent to the possibility of such disclosure.
Multiple Accounts
4.9 Only one KEF Account per Player is allowed. Should You attempt or successfully open more than one KEF Account, under Your own name or under any other name, or should You attempt or succeed in using the Website by means of any other person's KEF Account, KEF will be entitled to immediately close all Your KEF Account(s), retain all monies in such KEF Accounts and ban You from future use of the Website.
4.10 Should KEF have reason to believe that You have registered and/or used more than one KEF Account, or colluded with one or more other individuals using a number of different KEF Accounts, KEF shall be entitled to deem such accounts as constituting multiple KEF Accounts, and suspend or close all such KEF Accounts. KEF will also be entitled to retain the funds till the Player proves that he did not attempt to create multiple accounts.
4.11 If you notice that you have more than one registered KEF Account you must notify us immediately. Failure to do so may lead to your KEF Account being blocked and the funds retained.
User Responsibility
4.12 It is your sole and exclusive responsibility to ensure that your login details are kept securely. You must not disclose your login details to anyone.
4.13 We are not liable or responsible for any abuse or misuse of your KEF Account by third parties due to your disclosure, whether intentional, accidental, active or passive, of your login details to any third party.
4.14 You are prohibited from selling, transferring or acquiring KEF Accounts to or from other Players.
4.15 You will inform us as soon as you become aware of any errors with respect to your account or any calculations with respect to any bet you have placed. We reserve the right to declare null and void any bets that are subject to such an error.
4.16 You are responsible for all activities that occur under your account.
Security Features
4.17 We recommend that you enable two-factor authentication to enhance the security of your account.
4.18 We may provide other security measures from time to time, and we encourage you to use them.
Suspension and Closure by KEF
4.19 KEF shall be entitled to close or suspend Your KEF Account if:
a) KEF considers that You are using or have used the Website in a fraudulent or collusive manner or for illegal and/or unlawful or improper purposes;
b) KEF considers that You are using or have used the Website in an unfair manner, have deliberately cheated or taken unfair advantage of KEF or any of its customers or if Your KEF Account is being used for the benefit of a third party;
c) KEF is requested to do so by the police, any regulatory authority or court or if KEF is unable to verify Your identity, profession or source of funds as is expressly required by the applicable regulations;
d) You are in breach of these Terms and Conditions, the applicable regulations or the fair use of our services, or KEF has concerns that You are a compulsive problem gambler without being self-excluded;
e) KEF considers that any of the events referred to in (a) to (e) above may have occurred or are likely to occur.
4.20 If KEF closes or suspends Your KEF Account for any of the reasons referred to in 4.19, You shall, to the extent permitted by applicable laws, be liable for any and all claims, direct losses, liabilities, damages, costs and expenses incurred or suffered by KEF (together, the “Claims”) arising therefrom and shall, to the extent permitted by applicable laws, indemnify and hold KEF harmless on demand for such Claims.
4.21 In the circumstances referred to in 4.19, KEF shall also be entitled to void any bets placed by You following such actions by You or to withhold and/or retain any and all amounts which would otherwise have been paid or payable to you (including any winnings) to the extent permitted by law.
4.22 We reserve the right to suspend or terminate your account at any time, with or without notice, if we suspect that you account has been compromised or is being used in breach of our Terms of Service.
4.23 If KEF closes Your KEF Account it will inform You of the available means to withdraw the remaining balance on Your KEF Account.
Our Liability
4.24 We take no responsibility for any loss or damage that you may suffer as a result of unauthorised access to your account.
4.25 We take no responsibility for any loss or damage that you may suffer as a result of your failure to keep your login secure and private.
 </p>
 <br>
 <h2>YOUR WARRANTIES</h2>

 <p>5.1 Prior to your use of the Service and on an ongoing basis you represent, warrant, covenant and agree that:
Capacity
a) You are over 18 or such other legal age of majority as determined by any laws which are applicable to you, whichever age is greater;
b) You have full capacity to enter into a legally binding agreement with us and you are not restricted by any form of limited legal capacity;
c) You are not diagnosed or classified as a compulsive or problem gambler;
e) You are accessing CRYPTOKEF.COM from a jurisdiction in which it is legal to do so;
f) You will not use our services while located in any jurisdiction that prohibits the placing and/or accepting of bets online and/or playing casino and/or live games;
g) You accept and acknowledge that we reserve the right to detect and prevent the use of prohibited techniques, including but not limited to fraudulent transaction detection, automated registration and signup, gameplay and screen capture techniques. These steps may include, but are not limited to, examination of Players device properties, detection of geo-location and IP masking, transactions and blockchain analysis;
Funds & Tax
h) You are solely responsible for reporting and accounting for any taxes applicable to you under relevant laws for any winnings that you receive from us;
i) You are solely responsible for any applicable taxes which may be payable on cryptocurrency awarded to you through your using the Service;
j) There is a risk of losing cryptocurrency when using the Service and that KEF has no responsibility to you for any such loss;
k) You will not deposit funds which originate from criminal or other unauthorised activity;
l) You will not deposit funds using payment methods that do not belong to You;
m) All the funds deposited shall exclusively be used for Services available on the Website;
n) You will not withdraw or try to withdraw to a payment methods that do not belong to You;
o) You understand that by participating in the Services available on the Website, You take the risk of losing money deposited.
p) You accept and acknowledge that the value of cryptocurrency can change dramatically depending on the market value;
q) KEF shall not be treated as a financial institution;
Others
r) Your use of the Service is at your sole option, discretion and risk;
s) You will not conduct criminal activities through the KEF Account;
t) All information that you provide to us during the term of validity of this agreement is true, complete, correct, and that you shall immediately notify us of any change of such information;
u) You participate in the Games strictly in your personal and non-professional capacity and participate for recreational and entertainment purposes only;
v) You participate in the Games on your own behalf and not on the behalf of any other person;
w) You have only one account with us and agree to not to open any more accounts with us;
x) The telecommunications networks and Internet access services required for you to access and use the Service are entirely beyond the control of KEF and KEF shall have no liability whatsoever for any outages, slowness, capacity constraints or other deficiencies affecting the same;
y) You will not be involved in any fraudulent, collusive, fixing or other unlawful activity in relation to Your or any third parties’ participation in any of the games and/or services on the Website, and shall not use any software-assisted methods or techniques or hardware devices for Your participation in any of the games and/or services on the Website;
z) If you have access to non-public information related to an event or that can impact the outcome of an event or bet type, You will not bet on any event overseen by the relevant sport/event governing body;
aa) If You are an athlete, coach, manager, owner, referee, or anyone with sufficient authority to influence the outcome of an event You will not bet on any event overseen by the relevant sport or event of the governing body;
bb) If You are an owner (a person who is a direct or indirect legal or beneficial owner of 10 percent or greater) of a sport governing body or member team You will not bet on any event overseen by the sport governing body or any event in which a member team of that sport or event governing body participates;
cc) If You are involved in a sport or event You will not be involved in compiling betting odds for the competition in which You are involved.
5.2 In case of a breach of any of the representations, warrants or covenants mentioned in 5.1, KEF reserves the right to close or suspend Your KEF account at its own discretion and void any bets to the extent applicable by law.
</p>

<br>

<h2>KEF WARRANTIES</h2>
<p>6.1 KEF warrants that they will:
a) manage funds belonging to the Player in a secure and appropriate manner; and
b) manage personal information pertaining to the Player in accordance with applicable law, and in accordance with its Privacy Policy.
6.2 The software is provided "as is" without any warranties, conditions, undertakings or representations, express or implied, statutory or otherwise. KEF hereby excludes all implied terms, representations, conditions and warranties (including any of merchantability, merchantable quality, satisfactory quality and fitness for any particular purpose). KEF does not warrant that: (i) the Website and Services will meet Your requirements; (ii) the Website and Services will not infringe any third party’s intellectual property rights; (iii) the operation of the Website and Services will be error-free or uninterrupted; (iv) any defects in the Website and Services will be corrected; or (v) the Website or the servers are virus-free.
6.3 KEF can be contacted by email on supercryptomegagames@gmail.com or on its live chat 24/7. The live chat is available when logged into Your KEF Account. In the event that KEF, in its sole discretion, deems that Your behaviour, via live chat, email, or otherwise, has been abusive or derogatory towards any of KEF’s or its Affiliates or third-party service provider’s employees, KEF shall have the right to block or terminate Your KEF Account.
</p>
<br>





<h2>DEPOSITS</h2>
<p>7.1 You may participate in any Game only if you have sufficient funds on your KEF Account for such participation. For that purpose you shall use the payment methods available on the Website to deposit your funds. KEF will not give you any credit whatsoever for participation in any Game.
7.2 You must deposit funds to Your KEF Account using the payment methods available at cryptokef.com.
7.3 You shall ensure that funds that You deposit into your KEF Account are not tainted with any illegality and, in particular, do not originate from any illegal activity or source.
7.4 To deposit funds into your KEF Account, you can transfer funds from crypto-wallets under your control or through any other payment methods available on cryptokef.com. Deposits can only be made with your own funds.
7.5 You should only deposit money into Your account for the purpose of You using such money to place bets/wager on the Website. KEF is entitled to suspend or close Your account if we reasonably believe that You are depositing funds without any intention to place sporting and/or gaming wagers. In such circumstances we may also report this activity to relevant authorities.
7.6 You acknowledge and understand that funding Your KEF Account can only be funded by payment methods owned by You.
7.7 You further understand, agree and acknowledge that if KEF discovers, detects and/or identifies that You:
a) Funded/are funding Your KEF Account using third party payment methods; and/or
b) Funded/are funding Your KEF Account with funds that are tainted with illegality, such activity will be deemed as constituting a violation of the Terms of Service amounting to fraud, and by extension:
i) KEF reserves the right, at its own discretion, to suspend or close Your KEF Account; and
ii) KEF reserves the right, at its own discretion, to cancel, reverse or adjust any transactions and to forfeit funds deposited and/or winnings generated from the deposited funds.
7.8 KEF can set at its own discretion a minimum deposit amount. The minimum deposit amount can be changed at all time at KEF’s discretion and will be identified on the website. Please be aware that depending on the payment method used by You, additional fees might be charged by the payment providers.
7.9 The payment methods made available to you and the minimum and maximum deposit limit can be found in the wallet section on the Site. Applicable service fees may be applied and changed. Some payment methods may not be available in all countries.
7.10 Deposits are immediately processed and the updated balance is shown in the KEF Account instantly whenever a payment service provider is used. KEF does not take responsibility for any delays caused due to its payment system or due to delays caused by any third party. Note that:
a) some payment methods may include additional fees. In this case, the fee will be clearly visible for you in the cashier.
b) your bank or payment service provider may charge you additional fees for deposits of currency conversion according to their terms and conditions and your user agreement.
7.11 We reserve the right to use additional procedures and means to verify your identity when processing deposits into your KEF Account.
7.12 Funds cannot be transferred from your KEF Account to the KEF Account of another Player.
7.13 KEF can refuse any deposits at its own discretion. Users that have their accounts blocked, or suspended shall refrain from depositing at cryptokef.com.
7.14 In the event that a player tries to deposit when his account is blocked or suspended, KEF will have the right to retain the funds.
</p>
<br>





<h2>WITHDRAWALS</h2>
<p>8.1 KEF reserves the right to refuse any withdrawal by a Player from their KEF Account until:
a) the Player’s identity has been verified and KEF has confirmed the withdrawal is being made by a holder of the KEF Account;
b) the withdrawal is being transferred to an account of which the Player is a legal holder;
c) any additional information requested by KEF has been provided; and
d) the Player has complied with the minimum wager requirement for each deposit.
8.2 KEF reserves all rights to investigate Your account and gaming activity. If KEF reasonably suspects that Your account or gaming activity has violated these terms of service or applicable laws or regulations, it may, in its sole discretion, delay or decline further deposits, withdrawals and/or game play while it conducts its investigation. You acknowledge and accept that KEF may not be in a position to provide an explanation as to the nature of its investigation.
8.3 All withdrawals must be done through the same payment method chosen by you when placing a deposit, unless we decide otherwise or are unable to do so. If you deposit using a number of payment methods, we reserve the right to split your withdrawal across such payment methods and process each part through the respective payment method at our discretion and in accordance with anti-money laundering policies and regulation.
8.4 If we mistakenly credit your KEF Account with winnings that do not belong to you, whether due to a technical error in the pay-tables, or human error or otherwise, the amount will remain our property and will be deducted from your KEF Account. If you have withdrawn funds that do not belong to you prior to us becoming aware of the error, the mistakenly paid amount will (without prejudice to other remedies and actions that may be available at law) constitute a debt owed by you to us. In the event of an incorrect crediting, you are obliged to notify us immediately by email.
Crypto Withdrawals
8.10 Crypto withdrawals will be made to your stated cryptocurrency wallet address when making a valid withdrawal request.
8.11 KEF reserves the right to carry out additional KYC verification procedures for any withdrawal. Players who wish to recover funds held in a closed, locked or excluded account, are advised to contact supercryptomegagames@gmail.com.
8.12 All transactions shall be checked in order to prevent money laundering. If a player becomes aware of any suspicious activity relating to any of the Games of the Website, s/he must report this to KEF immediately. KEF may suspend, block or close a KEF Account and withhold funds if requested to do so in accordance with the Prevention of Money Laundering Act or on any other legal basis requested by any state authority. You acknowledge that the funds in your account are consumed instantly when playing and we do not provide return of goods, refunds or retrospective cancellation of your account.
</p>
<br>




<h2>PLAYER FUNDS PROTECTION & CHARGEBACKS</h2>
<p>9.1 Any funds You deposit with us in Your KEF Account, along with any winnings, are held for You in separate customer bank accounts / crypto wallet for the sole and specific purpose for You to place sports and gaming wagers and to settle any fees or charges that You might incur in connection with the use of our Services. This means Your funds are protected from being used for any other purpose.
9.2 If we incur any charge-backs, reversals or denial of payments or any loss suffered by KEF as a consequence thereof due to causes attributable to You in respect of Your KEF Account, we reserve the right to charge You for the relevant amounts incurred.
9.3 We may, at any time, offset any positive balance on Your account against any amounts owed by You to KEF.
 </p>
<br>



<h2>PLACING BETS/WAGERS</h2>
<p>10.1 You are allowed to place Your bets/wagers on the markets/products offered in the Website. KEF is not obliged to accept any bet/wager from You and bets/wagers will only be deemed as valid and finalised, and therefore as accepted by KEF, when You receive the confirmation from KEF of the acceptance of Your bet/wager.
10.2 KEF only accepts bets/wagers made online (including via mobile device). Bets/wagers are not accepted in any other form (post, email, fax, etc.) and where received will be invalid and void - win or lose.
10.3 It is Your responsibility to ensure details of the bets/wagers are correct. Once bets/wagers have been placed they may not be cancelled by You. Bets can only be changed by You using our Edit Bet feature, where this is available. KEF can only cancel or amend a bet/wager if the relevant event has been suspended or cancelled, if there was an obvious error on the relevant bet or its odds, if the bet is placed in breach of the Terms or if required to do so for legal or regulatory reasons.
10.4 Your funds will be allocated to bets/wagers in the order they are placed and will not be available for any other use. KEF reserves the right to void and/or reverse any transactions made after a bet/wager has been placed involving allocated funds, either at the time or retrospectively.
 </p>
<br>




<h2>BET/WAGER CONFIRMATION</h2>
<p>11.1 Bets/wagers will only be valid once You receive the confirmation of the acceptance of Your bet/wager. Bets/wagers placed with insufficient funds in Your account will be void.
11.2 A bet/wager that You request will only be valid once accepted by KEF. Each valid bet/wager will receive a unique transaction code. We shall not be liable for the settlement of any bets/wagers which are not issued with a unique transaction code. If You are unsure about the validity of a bet/wager, please check Your account history, or contact our Customer Support Team (supercryptomegagames@gmail.com).
11.3 Should a dispute arise regarding the content of a bet, You and KEF agree that the KEF transaction log database of its internal control system will be the ultimate authority in such matters.
</p>
<br>





<h2>BONUS</h2>
<p>12.1 KEF, at its sole discretion, might offer from time to time, a number of Bonuses and Promotions. For example, The Million Dollar Race, the 50 Billionth Bet Bonanza, Coupons, Reloads, and Rakeback.
Eligibility
12.2 Upon successful registration for a KEF Account, You may be eligible to receive KEF Promotions and Bonuses. By accepting this agreement and registering for a KEF Account on the Website, You are also acknowledging and accepting to be bound by the rules and regulations associated with any Promotions and Bonuses offered to You by KEF.
Bonus T&C
12.3 All promotions, bonuses, or special offers are subject to the express terms of any bonus offered on the Website(s) and promotion-specific terms and conditions, if applicable, and any bonus credited to Your account must be used in adherence with such terms and conditions. By accepting a promotion, bonus, or special offer available on the Website(s), You consent to the terms and conditions of such promotion, bonus, or special offer and acknowledge that wagers must always be placed with cash balances before bonus balances can be used to wager. We reserve the right to withdraw any promotion, bonus, or special offer at any time.
Activation & Expiry
12.4 By activating a Bonus or Promotion, You confirm that You also agree to the applicable terms and conditions.
12.5 No promotion, bonus, or special offer will be accepted or honoured by the KEF following the expiration date of the promotion, bonus, or special offer, unless KEF in its sole discretion chooses to do so for any particular customer, promotion, bonus, or special offer. Expiration dates will be set forth in the specific rules or terms and conditions of the particular promotion, bonus, or special offer. Furthermore, KEF reserves the right, in its sole discretion, to change or modify any policy with respect to the earning or expiration of bonuses.
12.6 Once forfeited or de-activated, the Bonus will no longer be available to You (and cannot be re-activated at any time thereafter). The amount of any Bonus Funds that have already been credited to your KEF Account Balance will remain available to You.
Bonus Abuse & Fraud
12.7 In the event that KEF believes a Player of the Service is abusing or attempting to abuse a bonus or other promotion or is likely to benefit through abuse or lack of good faith from a policy adopted by KEF, then KEF may, at its sole discretion, deny, withhold, or withdraw from any Player any bonus or promotion, or terminate that Player’s access to the Services, the Software, and/or lock that Player’s account, either temporarily or permanently.
12.8 You may only open one (1) account on the Website. The opening of multiple accounts on the Website for the purpose of accumulating bonuses, promotions, special offers, or otherwise, shall be considered abusive behaviour.
KEF Rights
12.9 KEF reserves the right to remove bonuses from all inactive accounts or accounts that are identified as “bonus abusers”.
12.10 KEF reserves the right to cancel all bonuses that have not been claimed within the claiming period or 60 days, the shorter of the two.
12.11 KEF reserves the right to cancel any bonus at its sole discretion.
</p>
<br>


<h2>AUTHORITY/TERMS OF SERVICE</h2>
<p>13.1 You agree to the game rules described on the cryptokef.com website. KEF retains authority over the issuing, maintenance, and closing of the Service. The decision of KEF's management, concerning any use of the Service, or dispute resolution, is final and shall not be open to review or appeal.</p>
<br>

<h2>PROHIBITED USES</h2>
<p>PERSONAL USE
14.1 The Service is intended solely for the User's personal use. The User is only allowed to wager for his/her personal entertainment. Users may not create multiple accounts for the purpose of collusion, sports betting and/or abuse of service.
AML & SANCTIONS COMPLIANCE
14.2 KEF expressly prohibits and rejects the use of the Service for any form of illicit activity, including money laundering, terrorist financing or trade sanctions violations, consistent with various jurisdictions' laws, regulations and norms. To that end, the Service is not offered to individuals or entities subject to United States, European Union, or other global sanctions or watch lists. By using the Service, you represent and warrant that you are not so subject.
JURISDICTIONS
14.3 Persons located in or reside in Afghanistan, Australia, Belarus, Belgium, Colombia, Côte d'Ivoire, Cuba, Curaçao, Czech Republic, Democratic Republic of the Congo, Denmark, France, Germany, Greece, Iran, Iraq, Italy, Liberia, Libya, Lithuania, Netherlands, North Korea, Ontario, Pakistan, Portugal, Serbia, Slovakia, South Sudan, Spain, Sudan, Syria, Sweden, Switzerland, United Kingdom, United States, Zimbabwe (the "Prohibited Jurisdictions") are not permitted make use of the Service. For the avoidance of doubt, the foregoing restrictions on engaging in real-money play from Prohibited Jurisdictions applies equally to residents and citizens of other nations while located in a Prohibited Jurisdiction. Any attempt to circumvent the restrictions on play by any persons located in a Prohibited Jurisdiction or Restricted Jurisdiction, is a breach of this Agreement. An attempt at circumvention includes, but is not limited to, manipulating the information used by KEF to identify your location and providing KEF with false or misleading information regarding your location or place of residence.
14.4 The attempt to manipulate your real location through the use of VPN, proxy, or similar services or through the provision of incorrect or misleading information about your place of residence, with the intent to circumvent geo-blocking or jurisdiction restrictions, constitutes a breach of Clause 5 of this Terms of Service.

    </p>
<br>



<h2>YOUR EQUIPMENT</h2>
<p>15.1 Your computer equipment or mobile device and internet connection may affect the performance and/or operation of the Website. KEF does not guarantee that the Website will operate without faults or errors or that KEF services will be provided without interruption. KEF does not accept any liability for any failures or issues that arise due to Your equipment, internet connection or internet or telecommunication service provider (including, for example, if You are unable to place bets or wagers or to view or receive certain information in relation to particular events).
15.2 For customers using a mobile device for the placing of bets/wagers, please note that KEF will not be responsible for any damage to, or loss of data from the mobile device that the software is installed on, and will also not be responsible for any call, data or other charges incurred whilst using the software.
15.3 Due to limited display sizes on mobile devices, the mobile experience might differ slightly from other platforms. Differences might include, but are not limited to, the location of certain information on the platform and game names not being visible on all game pages.
</p>
<br>










<h2>FAIR USE</h2>
<p>16.1 The Website and Services may only be used for recreational purposes by placing bets and wagers on events and/or gaming products.
16.2 You must not use the Website for the benefit of a third party or for any purpose which is illegal, defamatory, abusive or obscene, or which KEF considers discriminatory, fraudulent, dishonest or inappropriate. KEF may report to the authorities any activity which it considers to be suspicious and/or in breach of this paragraph.
16.3 If KEF has a reasonable suspicion that You are involved in fraudulent, dishonest or criminal acts, as set out under applicable laws, via or in connection with the Website or Services, KEF may seek criminal and contractual sanctions against You. KEF will withhold payment to any customer where any of these are suspected or where the payment is suspected to be for the benefit of a third party.
16.4 You shall indemnify and shall be liable to pay KEF, on demand, all costs, charges or losses sustained or incurred by us and our affiliates (including any direct, indirect or consequential losses, loss of profit and loss of reputation) in respect of all Claims arising directly or indirectly from Your fraudulent, dishonest or criminal acts while using the Website or Services
16.5 Furthermore, we reserve the right not to accept, process and/or honour bets/wagers where it would be forbidden, unlawful or illegal under applicable law or regulation to do so.
</p>
<br>




<h2>SOFTWARE AND TECHNOLOGY ISSUES</h2>
<p>17.1 In order for You to use the Website and Services, You may need to download some software (for example, casino games that are made available via a flash player). Also, certain third party product providers may require You to agree to additional terms and conditions governing the use of their products that are available on or through the Website. If You do not accept those third party terms and conditions, do not use the relevant third party software. KEF does not accept any liability in respect of any third party software.
17.2 You are only permitted to use any and all software made available to You via the Website for the purpose of using the Website and Services and, save to the extent permitted by applicable law, for no other purposes whatsoever.
17.3 We hereby grant to You a personal, non-exclusive, non-transferable right to use the Website for the sole purpose of accessing and using the Services on the Website, in accordance with these Terms and Conditions. This right to use our Website and will be immediately terminated once Your user registration is cancelled for any reason, and specially, but not limited to, if You make use of that right with the aim of generating a parallel enterprise based in our Website or our products, or with the aim of making use of an automated service or software analysing, capturing or somehow using the information shown in our Website.
17.4 You are not permitted to:
a) install or load the software that forms part of the Website onto a server or other networked device or take other steps to make the software available via any form of "bulletin board", online service or remote dial-in or network to any other person;
b) sub-license, assign, rent, lease, loan, transfer or copy (except as expressly provided elsewhere in these Terms and Conditions) Your right to use the Website, or the software that forms part of the Website, or make or distribute copies of same;
c) enter, access or attempt to enter or access or otherwise bypass KEF’s security system or interfere in any way (including but not limited to, robots or similar devices) with the products or the Website or attempt to make any modifications to the software and/or any features or components thereof;
d) copy or translate any user documentation provided 'online' or in electronic format.
e) In addition, except to the minimum extent permitted by applicable law in relation to computer programs, You are not permitted to: (i) translate, reverse engineer, decompile, disassemble, modify, create derivative works based on, or otherwise modify the Website; or (ii) reverse engineer, decompile, disassemble, modify, adapt, translate, make any attempt to discover the source code of the software that forms part of the Website or to create derivative works based on the whole or on any part of the Website.
17.5 You do not own the software that forms part of the Website. Such software is owned and is the exclusive property of KEF or a third party software provider company (any such third party provider, the "Software Provider"). Any software and accompanying documentation which have been licensed to KEF are proprietary products of the Software Provider and protected throughout the world by copyright law. Your use of the software does not give You ownership of any intellectual property rights in the software.
17.6 The software is provided "as is" without any warranties, conditions, undertakings or representations, express or implied, statutory or otherwise. KEF hereby excludes all implied terms, conditions and warranties, including any of merchantability, merchantable quality, satisfactory quality and fitness for any particular purpose, completeness or accuracy of the services or the software or infringement of applicable laws and regulations. KEF does not warrant or condition that: (i) the software will meet Your requirements; (ii) the software will not infringe any third party’s intellectual property rights; (iii) the operation of the software will be error free or uninterrupted; (iv) any defects in the software will be corrected; or (v) the software or the servers are virus-free.
17.7 In the event of communications or system errors occurring in connection with the settlement of accounts or other features or components of the software, neither KEF nor the Software Provider will have any liability to You or to any third party in respect of such errors. KEF reserves the right in the event of such errors to remove all relevant products from the Website and take any other action to correct such errors.
17.8 You hereby acknowledge that how You use the software is outside of KEF’s control. Accordingly, You install and/or use the software at Your own risk. KEF will not have any liability to You or to any third party in respect of Your receipt of and/or use of the software.
17.9 The software may include confidential information which is secret and valuable to the Software Provider and/or KEF. You are not entitled to use or disclose that confidential information other than strictly in accordance with these Terms and Conditions.
17.10 KEF shall not be liable if for any reason the Website is unavailable at any time or for any period. We reserve the right to make changes or corrections to or to alter, suspend or discontinue any aspect of the Website and the content or services or products available through it, including Your access to it.
17.11 You must not misuse the Website by introducing viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful. In particular, You must not access the Website without authority, interfere with, damage or disrupt the Website or any part of it, any equipment or network on which the Website is hosted, any software used in connection with the provision of the Website, or any equipment, software or website owned or used by a third party. You must not attack our Website via a denial-of-service attack. We will not be liable for any loss or damage caused by a distributed denial-of-service attack, viruses or other technologically harmful material that may infect Your computer equipment, computer programs, data or other proprietary material arising due to Your use of the Website, software or to Your downloading of any material posted on it, or on any website linked to it.
</p>
<br>



<h2>THIRD PARTY CONTENT</h2>
<p>18.1 KEF receives feeds, commentaries and content from a number of suppliers. Certain third party product providers may require You to agree to additional terms and conditions governing the use of their feeds, commentaries and content. If You do not accept the relevant third party terms and conditions, You agree to not use the relevant feeds, commentaries or content.
18.2 KEF does not accept any liability in respect of any third party feeds, commentaries and content.
18.3 Where the Website contains links to third party websites and resources, these links are provided for Your information only. KEF has no control over the content of these sites or resources, and accepts no liability for them or for any loss or damage that may arise from Your use of them. The inclusion of a link to a third party website does not constitute an endorsement of that third party’s website, product or services, if applicable.
19. ERRORS
</p>
<br>

<h2>ERRORS</h2>
<p>19.1 KEF will not be liable in the event 
    You try to or obtain an advantage from any errors
     in respect of bets or wagers on the Website if You were deliberately
      acting in bad-faith, including where: (i) there is an obvious error in the relevant odds,
       spreads, handicap, totals, cash-out; (ii) KEF continues to accept bets or wagers
        on closed or suspended markets; (iii) KEF incorrectly calculates or pays a settlement amount,
         including where a bet is Cashed Out for the full settlement amount, or a bet is made void incorrectly, 
         where ‘Void if player does not start’ was selected at bet placement;
          or (iv) any error occurs in a random number generator or pay tables included, 
          incorporated or used in any game or product.</p>
<br>



<h2>BREACH</h2>
<p>20.1 Without prejudice to any other rights, if a User breaches in whole or in part any provision contained herein, KEF reserves the right to take such action as it sees fit, including terminating this Agreement or any other agreement in place with the User and/or taking legal action against such User.
20.2 You agree to fully indemnify, defend and hold harmless KEF and its shareholders, directors, agents and employees from and against all claims, demands, liabilities, damages, losses, costs and expenses, including legal fees and any other charges whatsoever, howsoever caused, that may arise as a result of:
a) your breach of this Agreement, in whole or in part;
b) violation by you of any law or any third party rights; and
c) use by you of the Service.
</p>
<br>


<h2>LIMITATIONS AND LIABILITY</h2>
<p>21.1 Under no circumstances, including negligence, shall KEF be liable for any special, incidental, direct, indirect or consequential damages whatsoever (including, without limitation, damages for loss of business profits, business interruption, loss of business information, or any other pecuniary loss) arising out of the use (or misuse) of the Service even if KEF had prior knowledge of the possibility of such damages.
21.2 Nothing in this Agreement shall exclude or limit KEF's liability for death or personal injury resulting 
from its negligence.</p>
<br>


<h2>INTELLECTUAL PROPERTY</h2>
<p>22.1 KEF and its licensors are the sole holders of all rights in and to the Service and code, structure and organisation, including copyright, trade secrets, intellectual property and other rights. You may not, within the limits prescribed by applicable laws: (a) copy, distribute, publish, reverse engineer, decompile, disassemble, modify, or translate the website; or (b) use the Service in a manner prohibited by applicable laws or regulations (each of the above is an "Unauthorised Use"). KEF reserves any and all rights implied or otherwise, which are not expressly granted to the User hereunder and retain all rights, title and interest in and to the Service. You agree that you will be solely liable for any damage, costs or expenses arising out of or in connection with the commission by you of any Unauthorized Use. You shall notify KEF immediately upon becoming aware of the commission by any person of any Unauthorised Use and shall provide KEF with reasonable assistance with any investigations it conducts in light of the information provided by you in this respect.
22.2 The term "KEF", its domain names and any other trade marks, or service marks used by KEF as part of the Service (the "Trade Marks"), are solely owned by KEF. In addition, all content on the website, including, but not limited to, the images, pictures, graphics, photographs, animations, videos, music, audio and text (the "Site Content") belongs to KEF and is protected by copyright and/or other intellectual property or other rights. You hereby acknowledge that by using the Service, you obtain no rights in the Site Content and/or the Trade Marks, or any part thereof. Under no circumstances may you use the Site Content and/or the Trade Marks without KEF's prior written consent. Additionally, you agree not to do anything that will harm or potentially harm the rights, including the intellectual property rights of KEF.
</p>
<br>



<h2>DISPUTES</h2>
<p>23.1 If a User wishes to make a complaint, please contact KEF's customer service team at supercryptomegagames@gmail.com. 
    Should any dispute not be resolved to your satisfaction you may pursue remedies in the governing law jurisdiction set forth below.</p>
<br>

<h2>AMENDMENT</h2>
<p>24.1 KEF reserves the right to update or modify this Agreement or any part thereof at any time or otherwise change the Service without notice and you will be bound by such amended Agreement upon posting. Therefore, we encourage you check the terms and conditions contained in the version of the Agreement in force at such time. Your continued use of the Service shall be deemed to attest to your agreement to any amendments to the Agreement.</p>
<br>



<h2>GOVERNING LAW</h2>
<p>25.1 The Agreement and any matters relating hereto shall be governed by, and construed in accordance with, the laws of Curaçao. You irrevocably agree that, subject as provided below, the courts of Curaçao shall have exclusive jurisdiction in relation to any claim, dispute or difference concerning the Agreement and any matter arising therefrom and irrevocably waive any right that it may have to object to an action being brought in those courts, or to claim that the action has been brought in an inconvenient forum, or that those courts do not have jurisdiction. Nothing in this clause shall limit the right of KEF to take proceedings against you in any other court of competent jurisdiction, nor shall the taking of proceedings in any one or more jurisdictions preclude the taking of proceedings in any other jurisdictions, whether concurrently or not, to the extent permitted by the law of such other jurisdiction.</p>
<br>



<h2>SEVERABILITY</h2>
<p>26.1 If a provision of this Agreement is or becomes illegal, invalid or unenforceable in any jurisdiction, that shall not affect the validity or enforceability in that jurisdiction of any other provision hereof or the validity or enforceability in other jurisdictions of that or any other provision hereof.</p>
<br>


<h2>ASSIGNMENT</h2>
<p>27.1 KEF reserves the right to assign this agreement, in whole or in part, at any time without notice. The User may not assign any of his/her rights or obligations under this Agreement.</p>
<br>




<h2>MISCELLANEOUS</h2>
<p>28.1 No waiver by KEF of any breach of any provision of this Agreement (including the failure of KEF to require strict and literal performance of or compliance with any provision of this Agreement) shall in any way be construed as a waiver of any subsequent breach of such provision or of any breach of any other provision of this Agreement.
28.2 Nothing in this Agreement shall create or confer any rights or other benefits in favour of any third parties not party to this Agreement.
28.3 Nothing in this Agreement shall create or be deemed to create a partnership, agency, trust arrangement, fiduciary relationship or joint venture between you and KEF.
28.4 KEF may assign, transfer, charge, sub-license, or deal in any other manner with this Agreement, or sub-contract any of its rights and obligations under this Agreement, to any other party.
28.5 This Agreement constitutes the entire understanding and agreement between you and KEF regarding the Service and supersedes any prior agreement, understanding, or arrangement between you and KEF.
</p>
<br>



<h2>COMPLAINTS</h2>
<p>29.1 If you have a complaint to make regarding our services, you may contact our customer support via Live Chat or by email supercryptomegagames@gmail.com). We will endeavour to resolve the matter promptly.</p>
<br>


    </span>
    </div>
    
    <bottom-info></bottom-info>
    </template>
    
    
    <script>
import titleMixin from "@/components/UI/Other/document.title";
export default {
  mixins: [titleMixin],
  title: "Kef: Terms Conditions",
    
    
    
    
    
        components: {
    
    
        },
    
    
    }
    
    
    
    
    </script>
    
    
    <style scoped>
    
    
    
    </style>