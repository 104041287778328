<template>

    <Anime></Anime>
  </template>
  

  
       
<script>
import Anime from "./Anime.vue";
export default {
  components: {
    Anime,
  },
}
</script>
  <style scoped>
  
  
  </style>
  
  
  